import React from 'react'
import MenuItem from './MenuItem'

export const safeTitle = (title) =>
  title.toLowerCase().replace(/[^a-zA-Z]/g, '')

const SectionItems = ({ items, prefix, numberingStart }) => {
  return items.map((item, itemID) => (
    <MenuItem
      key={itemID}
      code={`${prefix ? prefix : ''}${itemID + numberingStart}`}
      chineseName={item.chineseName}
      englishName={item.englishName}
      spiceLevel={item.spiceLevel}
      price={item.price}
      extra={item.extra}
      variations={item.variations}
      seasonPrice={item.seasonPrice}
    />
  ))
}

export const Sections = ({ sections }) => {
  let component = []
  let numbering = {
    null: 1,
  }

  sections.forEach((section, sectionID) => {
    if (!numbering.hasOwnProperty(section.prefix)) numbering[section.prefix] = 1

    component.push(
      <div key={sectionID} className="section">
        <h2 id={safeTitle(section.title)}>{section.title}</h2>
        {section.extra && <p className="description">{section.extra}</p>}
        <table>
          <tbody>
            <SectionItems
              items={section.items}
              prefix={section.prefix}
              numberingStart={numbering[section.prefix]}
            />
          </tbody>
        </table>
      </div>
    )

    numbering[section.prefix] += section.items.length
  })

  return component
}
