import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { safeTitle, Sections } from '../components/CarteMenuSections'

const Sidebar = ({ sections }) => {
  let components = []
  components.push(
    <li>
      <Link to="/" key="home">
        Home
      </Link>
    </li>
  )

  sections.forEach(({ title }, id) => {
    components.push(
      <li>
        <a key={id} href={`#${safeTitle(title)}`}>
          {title}
        </a>
      </li>
    )
  })

  return (
    <div className="sidebar">
      <ul>{components}</ul>
    </div>
  )
}

const CarteMenuTemplate = ({ title, sections }) => (
  <div className="menu carte-menu">
    <Sidebar sections={sections} />
    <div className="menu-content">
      <h1>{title}</h1>
      <Sections sections={sections} />
    </div>
  </div>
)

CarteMenuTemplate.propTypes = {
  title: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.array,
    })
  ),
}

const CarteMenu = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CarteMenuTemplate
        title={post.frontmatter.title}
        sections={post.frontmatter.sections}
      />
    </Layout>
  )
}

CarteMenu.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CarteMenu

export const pageQuery = graphql`
  query CarteMenuById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        sections {
          title
          extra
          prefix
          items {
            chineseName
            englishName
            spiceLevel
            price
            extra
            seasonPrice
            variations {
              type
              price
            }
          }
        }
      }
    }
  }
`
