import React from 'react'
import PropTypes from 'prop-types'
import Chili from '../img/chili.png'
import { allButLastWord, justTheLastWord, formatPrice } from './util'

const Price = ({ price, variations, seasonPrice }) => {
  if (seasonPrice) return <p>season price</p>

  let component = []
  if (price) {
    component.push(<p key="price">{formatPrice(price)}</p>)
  }
  if (variations) {
    variations.forEach(({ type, price }, id) => {
      component.push(
        <p key={id}>
          {type} {formatPrice(price)}
        </p>
      )
    })
  }

  return component
}

export const MenuItem = ({
  code,
  chineseName,
  englishName,
  spiceLevel,
  price,
  extra,
  variations,
  seasonPrice,
}) => (
  <tr className="menu-item">
    <td className="code">{code}</td>
    <td className="chinese">{chineseName}</td>
    <td className="english-container">
      <p className="english">
        {allButLastWord(englishName)}
        <span className="no-wrap-chilli">
          {justTheLastWord(englishName)}
          <span className="spiceLevel">
            {Array(spiceLevel).fill(<img alt="one spice point" src={Chili} />)}
          </span>
        </span>
      </p>
      <p className="extra">{extra}</p>
    </td>
    <td className="price">
      <Price price={price} variations={variations} seasonPrice={seasonPrice} />
    </td>
  </tr>
)

MenuItem.propTypes = {
  code: PropTypes.string,
  chineseName: PropTypes.string,
  englishName: PropTypes.string,
  extra: PropTypes.string,
  spiceLevel: PropTypes.number,
  price: PropTypes.number,
  variations: PropTypes.array,
}

export default MenuItem
